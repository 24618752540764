/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import currency from "currency.js";
import { useNavigate } from "react-router-dom";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { TableWrapper } from "../../Table/style";
import { DataContext } from "../../../context/DataContext";
import { LinkText } from "../../Dashboard/style";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import {
  IManualAccount,
  useManualModeAccounts,
} from "../../../hooks/api/admin/useManualModeAccounts";
import {
  RequestWrapper,
  NoteWrapper,
  StatsWrapper,
  OverviewWrapper,
  Collateral,
  CollateralWrapper,
  Label,
  PositionsWrapper,
  OverrideInputWrapper,
  Stat,
  StatWrapper,
  Divider,
  AccountsList,
  ActionWrapper,
  ProfileDivider,
  BalanceInfo,
} from "../style";
import { useAccountFlow } from "../../../hooks/api/admin/useAccountFlow";
import { useAccountStats } from "../../../hooks/api/admin/useAccountStats";
import { useAccount } from "../../../hooks/api/admin/useAccount";
import { Spinner } from "../../shared/Spinner";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow-up-right.svg";
import ConfirmationModal from "../../shared/ConfirmationModal";
import { useNotes } from "../../../hooks/api/airtable/useNotes";
import { getCollateralLogo } from "../../../utils/asset";
import { Button, ButtonThemeEnum } from "../../shared/Buttons/styles";
import { IAccount } from "../../../interfaces/aevo";
import HeaderStats from "../../shared/Stats/HeaderStats";
import { WithdrawalsTable } from "./WithdrawalsTable";
import { useManualWithdrawals } from "../../../hooks/api/admin/useManualWithdrawals";
import { SPACING } from "../../../constants/design/spacing";

interface IAccountStatsProps {
  isAccountLoading: boolean;
  accountData: IAccount | undefined;
}
function AccountStats({ isAccountLoading, accountData }: IAccountStatsProps) {
  return (
    <StatWrapper
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridGap: 8,
      }}
    >
      <Stat>
        <span>Equity</span>
        <div>
          {isAccountLoading ? (
            <Spinner />
          ) : accountData && accountData.account ? (
            currency(accountData.equity).format()
          ) : (
            "-"
          )}
        </div>
      </Stat>
      <Stat>
        <span>Available Balance</span>
        <div>
          {isAccountLoading ? (
            <Spinner />
          ) : accountData && accountData.account ? (
            currency(accountData.available_balance).format()
          ) : (
            "-"
          )}
        </div>
      </Stat>
      <Stat>
        <span>Available Margin</span>
        <div>
          {isAccountLoading ? (
            <Spinner />
          ) : accountData && accountData.account ? (
            currency(accountData.available_margin).format()
          ) : (
            "-"
          )}
        </div>
      </Stat>
      <Stat>
        <span>Maintenance Margin</span>
        <div>
          {isAccountLoading ? (
            <Spinner />
          ) : accountData && accountData.account ? (
            currency(accountData.maintenance_margin).format()
          ) : (
            "-"
          )}
        </div>
      </Stat>
      <Stat>
        <span>Initial Margin</span>
        <div>
          {isAccountLoading ? (
            <Spinner />
          ) : accountData && accountData.account ? (
            currency(accountData.initial_margin).format()
          ) : (
            "-"
          )}
        </div>
      </Stat>

      <Stat>
        <span>Email</span>
        <div>
          {isAccountLoading ? (
            <Spinner />
          ) : accountData && accountData.account ? (
            accountData.email_address
          ) : (
            "-"
          )}
        </div>
      </Stat>

      <Stat>
        <span>In Liquidation Mode</span>
        <div>
          {isAccountLoading ? (
            <Spinner />
          ) : accountData && accountData.account ? (
            String(accountData.in_liquidation)
          ) : (
            "-"
          )}
        </div>
      </Stat>
    </StatWrapper>
  );
}

interface IManualAccountsTableProps {
  data: IManualAccount[];
  isLoading?: boolean;
  isMobile?: boolean;
}

export function ManualAccountsTable({
  data,
  isLoading,
  isMobile,
}: IManualAccountsTableProps) {
  const navigate = useNavigate();
  const { records } = useContext(DataContext);
  const { setManualMode, isLoading: isUnflagLoading } = useManualModeAccounts();
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [showFlagModal, setShowFlagModal] = useState<boolean>(false);
  const [showUnflagModal, setShowUnflagModal] = useState<boolean>(false);
  const [isQuerying, setIsQuerying] = useState<boolean>(false);
  const [overrideAddress, setOverrideAddress] = useState<string>("");

  const {
    data: notesData,
    isLoading: isNotesLoading,
    isValidating: isNotesValidating,
  } = useNotes();
  const { data: flowData, isLoading: isFlowLoading } = useAccountFlow(account!);
  const { data: infoData, isLoading: isInfoLoading } = useAccountStats(
    account!
  );
  const { data: accountData, isLoading: isAccountLoading } = useAccount(
    account!
  );
  const { data: manualWithdrawalsData = [], isLoading: isWithdrawalsLoading } =
    useManualWithdrawals();

  useEffect(() => {
    if (isAccountLoading) {
      setIsQuerying(true);
    } else {
      setIsQuerying(false);
    }
  }, [isAccountLoading]);

  const onUnflag = useCallback((acc: string) => {
    setAccount(acc);
    setShowUnflagModal(true);
  }, []);

  const onFlag = useCallback((acc: string) => {
    setAccount(acc);
    setShowFlagModal(true);
  }, []);

  const hasNote = useCallback(
    (acc: string) => {
      if (isNotesLoading && !isNotesValidating) return false;
      return (notesData || []).some((n) => n.address === acc);
    },
    [isNotesLoading, isNotesValidating, notesData]
  );

  const memoizedAccounts = useMemo(() => {
    // Sort by accounts that have a record on airtable
    const sortedAccounts = data.sort((a, b) => {
      if (records[a.account] && !records[b.account]) return -1;
      if (!records[a.account] && records[b.account]) return 1;
      return 0;
    });

    // Sort account that has a note using callback
    sortedAccounts.sort((a, b) => {
      if (hasNote(a.account) && !hasNote(b.account)) return 1;
      if (!hasNote(a.account) && hasNote(b.account)) return -1;
      return 0;
    });

    return sortedAccounts.filter(
      (a) => a.withdraws.length > 0 || a.manual_mode
    );
  }, [data, hasNote, records]);

  interface IInstrumentRealizedPnL {
    asset: string;
    profit: string;
    loss: string;
  }

  const sortedInstruments = useMemo(() => {
    if (!infoData?.instrument_realized_pnl) return [];

    return Object.entries(infoData.instrument_realized_pnl).sort(
      ([, a], [, b]) => Number(b) - Number(a)
    );
  }, [infoData?.instrument_realized_pnl]);

  const instrumentRealizedPnLArr = useMemo(() => {
    if (!infoData?.instrument_realized_pnl) return [];

    const pnl: IInstrumentRealizedPnL[] = [];

    sortedInstruments.forEach(([key, value]) => {
      const isPerp = key.split("-")?.[1] === "PERP";
      pnl.push({
        asset: isPerp ? key.split("-")?.[0] : "",
        profit: Number(value) > 0 ? value : "0",
        loss: Number(value) < 0 ? value : "0",
      });
    });

    return pnl;
  }, [infoData?.instrument_realized_pnl, sortedInstruments]);

  const cumulativeRealizedPnL = useMemo(() => {
    if (!infoData?.instrument_realized_pnl) return 0;

    return Object.values(infoData?.instrument_realized_pnl).reduce(
      (acc, curr) => acc + Number(curr),
      0
    );
  }, [infoData]);

  const accountNote = useCallback(
    (acc: string) => {
      if (isNotesLoading && !isNotesValidating) return <Spinner />;
      const note = (notesData || []).find((n) => n.address === acc);

      if (!note) return undefined;

      return (
        <NoteWrapper>
          <span>{note?.assignee?.name}</span>
          <div>{note?.note}</div>
        </NoteWrapper>
      );
    },
    [isNotesLoading, isNotesValidating, notesData]
  );

  const accountWithdrawals = useCallback(
    (acc: string) => {
      const withdrawals = manualWithdrawalsData.filter(
        (w) => w.account === acc
      );
      return withdrawals;
    },
    [manualWithdrawalsData]
  );

  return (
    <div>
      <ConfirmationModal
        width={"200px"}
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Unflag Account"}
        extras={
          <RequestWrapper>
            Are you sure you want to unflag{" "}
            <div
              style={{
                color: records[account!] ? COLORS.blue.one : TEXT_COLORS.one,
              }}
            >
              {records[account!] ? records[account!] : account!}
            </div>
            ? Doing so will clear all pending withdrawals made by this account.
          </RequestWrapper>
        }
        show={showUnflagModal}
        onHide={() => setShowUnflagModal(false)}
        confirmationButton={{
          title: "Unflag",
          onClick: async () => {
            if (account) {
              setManualMode(account, false).finally(() => {
                setShowUnflagModal(false);
                setAccount(undefined);
              });
            }
          },
          disabled: isLoading || !account,
        }}
      />
      <ConfirmationModal
        width={"200px"}
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Flag Account"}
        extras={
          <RequestWrapper>
            Are you sure you want to flag{" "}
            <div
              style={{
                color: records[account!] ? COLORS.blue.one : TEXT_COLORS.one,
              }}
            >
              {records[account!] ? records[account!] : account!}
            </div>
            ?
          </RequestWrapper>
        }
        show={showFlagModal}
        onHide={() => setShowFlagModal(false)}
        confirmationButton={{
          title: "Flag",
          onClick: async () => {
            if (account) {
              setManualMode(account, true).finally(() => {
                setShowFlagModal(false);
              });
            }
          },
          disabled: isLoading || !account,
        }}
      />
      <HeaderStats
        isMobile={isMobile}
        records={records}
        accountData={accountData}
        setAccount={setAccount}
        onFlag={onFlag}
      />
      <OverviewWrapper isMobile={isMobile}>
        {!isMobile || (isMobile && account) ? (
          <StatsWrapper isMobile={isMobile}>
            <AccountStats
              isAccountLoading={isAccountLoading}
              accountData={accountData}
            />
            <StatWrapper>
              <Label>Asset Breakdown</Label>
              <BalanceInfo>
                <div>
                  <span />
                  Balance
                </div>
                <div>
                  <span />
                  Available Balance
                </div>
                <div>
                  <span />
                  USD Denom. Balance
                </div>
              </BalanceInfo>
              <div>
                {isAccountLoading ? (
                  <Spinner />
                ) : accountData && accountData.account ? (
                  <CollateralWrapper>
                    {accountData.collaterals.map((c) => (
                      <div key={c.collateral_asset}>
                        <Collateral>
                          <div>
                            {currency(Number(c.balance)).format({
                              symbol: "",
                            })}{" "}
                            {c.collateral_asset}{" "}
                          </div>
                          <span style={{ color: COLORS.orange.one }}>
                            {currency(Number(c.available_balance)).format({
                              symbol: "",
                            })}{" "}
                            {c.collateral_asset}{" "}
                          </span>
                          <span style={{ color: COLORS.blue.one }}>
                            {currency(c.collateral_value).format()}
                          </span>
                        </Collateral>
                        <img
                          src={getCollateralLogo(c.collateral_asset)}
                          alt={c.collateral_asset}
                        />
                      </div>
                    ))}
                  </CollateralWrapper>
                ) : (
                  "-"
                )}
              </div>
            </StatWrapper>
            <StatWrapper>
              <Label>Flow Breakdown</Label>
              <Divider style={{ gridTemplateColumns: "repeat(3, 1fr)" }}>
                <Stat>
                  <span>Inflows</span>
                  <div>
                    {isFlowLoading ? (
                      <Spinner />
                    ) : flowData ? (
                      currency(flowData.inflows.total).format()
                    ) : (
                      "-"
                    )}
                  </div>
                </Stat>
                <Stat>
                  <span>Outflows</span>
                  <div>
                    {isFlowLoading ? (
                      <Spinner />
                    ) : flowData ? (
                      currency(flowData.outflows.total).format()
                    ) : (
                      "-"
                    )}
                  </div>
                </Stat>
                <Stat>
                  <span>Net Flow</span>
                  <div>
                    {isFlowLoading ? (
                      <Spinner />
                    ) : flowData ? (
                      currency(flowData.total).format()
                    ) : (
                      "-"
                    )}
                  </div>
                </Stat>
              </Divider>
            </StatWrapper>
            <StatWrapper>
              <Label>PnL</Label>
              <Divider style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
                <Stat>
                  <span>Unrealized PnL</span>
                  <div
                    style={{
                      color:
                        infoData && Number(infoData?.pnl) !== 0
                          ? Number(infoData?.pnl) > 0
                            ? COLORS.green.one
                            : COLORS.red.one
                          : TEXT_COLORS.two,
                    }}
                  >
                    {isInfoLoading ? (
                      <Spinner />
                    ) : infoData ? (
                      `${Number(infoData?.pnl || 0) > 0 ? "+" : "-"}${currency(
                        infoData.pnl
                      ).format()}`
                    ) : (
                      "-"
                    )}
                  </div>
                </Stat>
                <Stat>
                  <span>Realized PnL</span>
                  <div
                    style={{
                      color:
                        infoData && Number(infoData?.realized_pnl) !== 0
                          ? Number(infoData?.realized_pnl) > 0
                            ? COLORS.green.one
                            : COLORS.red.one
                          : TEXT_COLORS.two,
                    }}
                  >
                    {isInfoLoading ? (
                      <Spinner />
                    ) : infoData ? (
                      `${
                        Number(infoData?.realized_pnl || 0) > 0 ? "+" : ""
                      }${currency(infoData.realized_pnl).format()}`
                    ) : (
                      "-"
                    )}
                  </div>
                </Stat>
              </Divider>
            </StatWrapper>
            <StatWrapper>
              <Label>
                Realized Positions ({instrumentRealizedPnLArr.length})
              </Label>
              <div>
                {isInfoLoading ? (
                  <Spinner />
                ) : infoData ? (
                  <PositionsWrapper>
                    <div>
                      <span style={{ color: COLORS.blue.one }}>
                        Cumulative PNL
                      </span>
                      <div
                        style={{
                          color:
                            Number(cumulativeRealizedPnL) > 0
                              ? COLORS.green.one
                              : COLORS.red.one,
                        }}
                      >
                        {currency(cumulativeRealizedPnL).format()}
                      </div>
                    </div>
                    {sortedInstruments.map(([key, value]) => (
                      <div key={key} style={{ display: "flex" }}>
                        <span>{key}</span>
                        <div
                          style={{
                            color:
                              Number(value) > 0
                                ? COLORS.green.one
                                : COLORS.red.one,
                          }}
                        >
                          {currency(value).format()}
                        </div>
                      </div>
                    ))}
                  </PositionsWrapper>
                ) : (
                  "-"
                )}
              </div>
            </StatWrapper>
          </StatsWrapper>
        ) : undefined}
        {!isMobile || (isMobile && !account) ? (
          <TableWrapper style={{ border: "none", paddingLeft: 0 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: isMobile ? `${SPACING.two}px 0` : SPACING.three,
              }}
            >
              <OverrideInputWrapper isMobile={isMobile}>
                <input
                  type="text"
                  placeholder="Override address"
                  value={overrideAddress}
                  onChange={(e) => setOverrideAddress(e.target.value)}
                />
                <Button
                  style={{ padding: SPACING.two }}
                  disabled={isQuerying}
                  buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                  onClick={() => setAccount(overrideAddress!)}
                >
                  {!isQuerying ? "Query" : <Spinner />}
                </Button>
              </OverrideInputWrapper>
              <div style={{ display: "flex" }}>
                <a
                  style={{
                    color: COLORS.blue.one,
                    fontSize: 12,
                    margin: "auto",
                    marginRight: SPACING.two,
                  }}
                  href={
                    "https://airtable.com/appbIJLOZuQLpoWd3/tblMg6sIOyQ1Wh4qz/viwj55vko3yk3eQj1"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notes {isMobile ? "" : "Airtable"}{" "}
                  <Arrow style={{ stroke: COLORS.blue.one }} />
                </a>
              </div>
            </div>
            <AccountsList isMobile={isMobile}>
              {memoizedAccounts.length > 0
                ? memoizedAccounts.map((acc) => (
                    <button
                      type="button"
                      key={acc.account}
                      onClick={() => setAccount(acc.account)}
                    >
                      <ProfileDivider
                        style={{ background: LAYER_COLORS.one }}
                        isMobile={isMobile}
                        isActive={accountData?.account === acc.account}
                        isNoted={hasNote(acc.account)}
                      >
                        <LinkText style={{ margin: "auto 0" }}>
                          <div
                            style={{
                              color: hasNote(acc.account)
                                ? COLORS.red.one
                                : records[acc.account!]
                                ? COLORS.blue.one
                                : TEXT_COLORS.one,
                              fontSize: isMobile
                                ? FONT_SIZE.two
                                : FONT_SIZE.three,
                            }}
                          >
                            {records[acc.account!]
                              ? records[acc.account!]
                              : acc.account}
                          </div>
                          {records[acc.account!] ? (
                            <div
                              style={{
                                color: TEXT_COLORS.two,
                                fontSize: FONT_SIZE.one,
                              }}
                            >
                              {acc.account}
                            </div>
                          ) : undefined}
                          {accountData?.account === acc.account &&
                          accountData?.username ? (
                            <div
                              style={{
                                color: TEXT_COLORS.two,
                                fontSize: FONT_SIZE.one,
                              }}
                            >
                              {accountData.username}
                            </div>
                          ) : undefined}
                        </LinkText>
                        <ActionWrapper isMobile={isMobile}>
                          <Button
                            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                            onClick={() => navigate(`/account/${acc.account}`)}
                            disabled={isAccountLoading}
                          >
                            Go to Profile
                          </Button>
                          <Button
                            buttonTheme={ButtonThemeEnum.NEGATIVE}
                            onClick={() => onUnflag(acc.account)}
                            disabled={isUnflagLoading}
                          >
                            Unflag
                          </Button>
                        </ActionWrapper>
                      </ProfileDivider>
                      {accountNote(acc.account)}
                      {accountWithdrawals(acc.account).length > 0 ? (
                        <div>
                          <Label
                            style={{ padding: SPACING.three, paddingTop: 0 }}
                          >
                            Withdrawals (
                            {accountWithdrawals(acc.account).length})
                          </Label>
                          <WithdrawalsTable
                            isMobile={isMobile}
                            data={accountWithdrawals(acc.account)}
                            isLoading={isWithdrawalsLoading}
                          />
                        </div>
                      ) : undefined}
                    </button>
                  ))
                : null}
            </AccountsList>
          </TableWrapper>
        ) : undefined}
      </OverviewWrapper>
    </div>
  );
}
