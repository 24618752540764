import { useRef } from "react";
import { useManualModeAccounts } from "../../hooks/api/admin/useManualModeAccounts";
import { WithdrawalContentWrapper } from "./style";
import { ManualAccountsTable } from "./tables/ManualAccountsTable";
import { COMPONENTS } from "../../constants/design/spacing";
import { MobileDirectory } from "../shared/MobileDirectory";

interface IWithdrawProps {
  isMobile?: boolean;
}

export function Withdraw({ isMobile }: IWithdrawProps) {
  const { data: manualAccountsData = [] } = useManualModeAccounts();
  const withdrawalRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {isMobile ? (
        <div ref={withdrawalRef}>
          <MobileDirectory />
        </div>
      ) : undefined}
      <WithdrawalContentWrapper
        isMobile={isMobile}
        style={{
          height: `calc(100dvh - ${
            COMPONENTS.header.mobile +
            (withdrawalRef.current?.clientHeight || 0)
          }px)`,
        }}
      >
        <ManualAccountsTable data={manualAccountsData} isMobile={isMobile} />
      </WithdrawalContentWrapper>
    </>
  );
}
