import { useMemo, useState } from "react";
import { ISelectOption, Select } from "../shared/Select";
import {
  ContentWrapper,
  DebugWrapper,
  OptionsWrapper,
  Padding,
  SelectWrapper,
} from "./style";
import { CreateQuote } from "./CreateQuote";

enum OtcEnum {
  CREATE_QUOTE,
}

export function Otc() {
  const [otcOption, setOtcOption] = useState<OtcEnum>(OtcEnum.CREATE_QUOTE);

  const options: ISelectOption[] = useMemo(
    () => [
      {
        label: "Create Quote",
        isActive: otcOption === OtcEnum.CREATE_QUOTE,
        onClick: () => setOtcOption(OtcEnum.CREATE_QUOTE),
      },
    ],
    [otcOption]
  );

  return (
    <DebugWrapper>
      <Padding>
        <OptionsWrapper>
          <SelectWrapper>
            <Select options={options} />
          </SelectWrapper>
        </OptionsWrapper>
        <ContentWrapper>
          <CreateQuote />
        </ContentWrapper>
      </Padding>
    </DebugWrapper>
  );
}
